// Base Class Definition
// -------------------------

.#{$fa-css-prefix} {
  display: inline-block;
  font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*="fa-"]{
  &:before{
    display: inline-block;
    font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base}  FontAwesome; // shortening font declaration
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 7px;
  }
}

.fa-right{
  &:before{
    display: inline-block;
    font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base}  FontAwesome; // shortening font declaration
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 0;
    padding-left: 5px;
  }
}



