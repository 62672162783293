// media
$xs:            320px !default;
$xs-landscape:  480px !default;
$sm:            766px !default;
$md:            992px !default;
$lg:            1200px !default;
$xlg:           1600px !default;

$xs-max:        $xs - 1 !default;
$xs-landscape:  $xs - 1 !default;
$sm-max:        $sm - 1 !default;
$md-max:        $md - 1 !default;
$lg-max:        $lg - 1 !default;
$xlg-max:       $xlg - 1 !default;

// container
$container-xs:    100%;
$container-sm:    720px;
$container-md:    960px;
$container-lg:    1200px;




// Fonts
$font-family-base:     "Lato", Helvetica, Arial, sans-serif !default;

// font-sizes
$font-size-base:       16px  !default;

$font-size-xs:         14px  !default;
$font-size-small:      18px  !default;
$font-size-medium:     ($font-size-base * 1.5) !default;  // 24px
$font-size-large:      ($font-size-small * 2)  !default;  // 36px
$font-size-title:      ($font-size-base * 3) !default;    // 48px
$font-size-major:      ($font-size-base * 3.75) !default; // 60px
$font-size-lg:         ($font-size-base * 5.25) !default; // 84px


// font weight & styles
$font-weight-normal:  normal;
$font-weight-bold:    bold;
$font-weight-black:   900;
$font-style-normal:   normal;
$font-style-italic:   italic;

// line height
$line-height-base:    1.428571429 !default;
$line-height-normal:  1 !default;

// colors
$global-color:        #fff;
$brand-color:         #00bf40;
$gray:                #2e3033;
$gray-base:           #000;
$gray-light:          #f0f2f7;
$gray-article:        #6d7a83;

// common colors
$body-bg:             $global-color;
$header-bg:           $gray;
$text-color:          $gray-base;

// socials colors
$fb-color:            #496194;
$vk-color:            #6996c7;

// transitions
$a-transition:        .15s ease-in-out;
$a-transition-long:   .3s ease-in-out;

//** Padding applied to the modal body
$modal-inner-padding:                 15px !default;
$modal-title-padding:                 15px !default;
$modal-title-line-height:             $line-height-base !default;
$modal-content-bg:                    #fff !default;
$modal-content-border-color:          rgba(0,0,0,.2) !default;
$modal-content-fallback-border-color: #999 !default;
$modal-backdrop-bg:                   #000 !default;
$modal-backdrop-opacity:              .6 !default;
$modal-header-border-color:           #e5e5e5 !default;
$modal-footer-border-color:           $modal-header-border-color !default;
$zindex-modal:                        10000002;
$zindex-modal-background:             10000001;