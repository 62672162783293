@charset "utf-8";

// variables
@import "variables";
// mixins
@import "mixins/mixins";
// libraries
@import "libraries/libraries";
//  layot
@import "layout/layout";

// :selection
@include selection-style($brand-color, $global-color);

// scrollbar
@include scrollbar-style(7px, 5px, $brand-color, 0, $global-color);

//body

body {
  &.hidden {
    overflow: hidden !important;
  }
}

// header
.header {
  @include position(fixed, 0 0 null 0);
  min-height: 60px;
  background: $header-bg;
  transition: $a-transition;
  will-change: position;
  z-index: 100000;

  @include lg {
    min-height: 96px;
    position: relative;
  }

  &.active {
    .nav {
      transform: translateX(0);
    }
  }

  &-wrapper {
    @include lg {
      @include clearfix;
    }
  }
}

// navigation
.nav {
  @include max-lg {
    @include absolute(0 0 null null);
    background: $header-bg;
    @include size(100% 100vh);
    will-change: transform;
    transform: translateX(100%);
    transition: $a-transition-long;
    z-index: 99;
  }

  @include sm {
    @include size(50% 100vh);
  }
  @include lg {
    @include size(auto);
    float: left;
    margin-left: 30px;
  }

  ul {
    @include size-reset();
    @include list-unstyled();

    @include max-md {
      padding: 60px 0;
    }

    @include max-lg {
      padding: 96px 0;
    }

    @include lg {
      @include cl;
    }

    li {
      display: block;

      @include lg {
        float: left;
      }

      a {
        position: relative;
        display: block;
        padding: 15px 20px;
        font: {
          size: $font-size-small;
          weight: $font-weight-bold;
        }
        color: $global-color;
        text-transform: uppercase;
        transition: $a-transition;
        line-height: $line-height-normal;

        @include sm {
          padding: 25px 40px;
          font-size: $font-size-medium;
        }

        @include lg {
          padding: 37px 12px;
          font-size: $font-size-base;
          line-height: $line-height-base;

          &:before,
          &:after {
            content: '';
            @include size(100% 3px);
            background-color: $brand-color;
            transition: $a-transition;
            opacity: 0;
            will-change: opacity, transform;
          }

          &:before {
            @include absolute(0 null null 0);
            top: 30px;
          }

          &:after {
            @include absolute(null 0 0 null);
            bottom: 30px;
          }
        }
      }

      &.active,
      &:hover {
        a {
          color: $brand-color;

          @include lg {
            &:before,
            &:after {
              opacity: 1;
            }

            &:before {
              top: 0;
            }
            &:after {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

.right-side {
  display: none;

  @include sm {
    display: block;
    float: right;
    @include clearfix;
    margin-right: 110px;
  }

  @include lg {
    margin-right: 0;
  }

  .btn {
    @include sm {
      margin: 24px 30px 0 20px;
      float: left;
    }
  }
}

.header-contacts {
  @include size-reset();
  @include list-unstyled();

  @include sm {
    @include cl;
    float: left;
  }

  li {
    display: block;
    @include sm {
      float: left;
    }

    a {
      position: relative;
      display: block;
      padding: 37px 12px;
      font: {
        size: $font-size-base;
        weight: $font-weight-bold;
      }
      color: $global-color;
      transition: $a-transition;

      &:hover {
        color: $brand-color;
      }
    }
  }
}

.btn-nav {
  @include absolute(0 0 null null);
  z-index: 1000;
  @include lg {
    display: none;
  }
  padding: 28px;
  border-radius: 0;
  background-color: transparent;

  @include sm {
    padding: 47px;
  }

  .span-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 22px;
    width: 32px;
    //@include size(24px);
  }

  span {
    display: block;
    @include size(32px 2px);
    position: absolute;
    left: 0;
    background: $global-color;
    will-change: transform, position;
    transition: .15s ease-in;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 11px;
      transition: .1s ease-in-out;
    }
    &:nth-of-type(3) {
      top: 22px;
    }
  }

  &.active {
    span {
      &:nth-of-type(1) {
        //top: 5px;
        transform: rotate(45deg) translate(28%, 384%);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 12px;
        transform: rotate(-45deg);
      }
    }
  }
}

// submenu
.submenu {
  position: relative;
  display: none;
  background-color: $gray;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, .35);

  @include sm {
    display: block;
  }

  > ul {
    @include list-unstyled();
    @include size-reset();
    @include container();

    > li {
      display: block;
      position: relative;

      &:hover {
        a {
          span {
            color: $brand-color;

            &:after {
              opacity: 1;
            }
          }
        }

        @include lg {
          .submenu-hover {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      @include sm {
        @include span-columns(4);

        &:nth-last-of-type(3n) {
          @include omega();
        }
      }

      @include lg {
        @include span-columns(2);

        &:nth-last-of-type(1) {
          @include omega();
        }
      }
    }
  }

  .category-link {
    display: block;
    font: {
      size: $font-size-small;
      weight: $font-weight-bold;
    }
    color: $global-color;
    line-height: $line-height-normal;
    text-align: center;
    cursor: pointer;
    transition: $a-transition-long;

    span {
      position: relative;
      display: block;
      padding: 36px 0 30px;
      z-index: 40000;

      &:before {
        content: '';
        position: relative;
        display: block;
        @include size(50px 48px);
        margin: auto;
        background: {
          repeat: no-repeat;
          image: url(./../img/submenu-icons-sprite.png);
          size: inherit;
        }
        margin-bottom: 30px;
        transition: $a-transition;
        z-index: 300;
      }
      @include lg {

        &:after {
          content: '';
          @include size(100% 155px);
          background-color: $global-color;
          @include absolute(-13px null null 0);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          z-index: -1;
          opacity: 0;
        }
      }
    }
  }
}

.jalousie {
  a {
    span {
      &:before {
        background-position: 0 0;
      }
      &:hover {
        &:before {
          background-position: 0 -48px;
        }
      }
    }
  }
}

.blinds {
  a {
    span {
      &:before {
        background-position: -197px 0;
      }
      &:hover {
        &:before {
          background-position: -197px -48px;
        }
      }
    }
  }
}

.mosquito {
  a {
    span {

      &:before {
        background-position: -397px 0;
      }
      &:hover {
        &:before {
          background-position: -397px -48px;
        }
      }
    }
  }
}

.sun {
  a {
    span {
      &:before {
        background-position: -597px 0;
      }
      &:hover {
        &:before {
          background-position: -597px -48px;
        }
      }
    }
  }
}

.roller {
  a {
    span {
      &:before {
        background-position: -799px 0;
      }
      &:hover {
        &:before {
          background-position: -799px -48px;
        }
      }
    }
  }
}

.eaves {
  a {
    span {
      &:before {
        background-position: -969px 0;
      }
      &:hover {
        &:before {
          background-position: -969px -48px;
        }
      }
    }
  }
}

.submenu-hover {
  @include list-unstyled();
  @include size-reset();
  @include absolute(137px null null 0);
  @include size(100% auto);
  background-color: $global-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1000000;
  padding: 10px 0;
  transition: $a-transition;
  opacity: 0;
  box-shadow: 0px 12px 12px 3px rgba(0, 0, 0, 0.35);
  visibility: hidden;

  > li {
    display: block;
    text-align: center;

    > a {
      display: block;
      color: $gray;
      padding: 10px 15px;
      font: {
        size: $font-size-xs;
        weight: $font-weight-bold;
      }
      transition: $a-transition;
      line-height: $line-height-base;

      &:hover {
        color: $brand-color;
      }
    }
  }
}

// altenative block

.alternative {
  padding: 75px 0;
  background: $gray-light;

  @include lg {
    padding: 100px 0;
  }

  ul {
    @include list-unstyled();
    @include size-reset();
    @include container();

    li {
      display: block;
      margin-bottom: 30px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      text: {
        align: center;
        transform: uppercase;
      }
      font: {
        size: $font-size-medium;
        weight: $font-weight-bold;
      }
      line-height: $line-height-normal;

      @include sm {
        margin-bottom: 50px;

        &:nth-last-of-type(2) {
          margin-bottom: 0;
        }
      }

      @include lg {
        margin-bottom: 0;
      }

      @include sm {
        @include span-columns(6);

        &:nth-of-type(even) {
          @include omega()
        }
      }

      @include lg {
        @include span-columns(3);

        &:nth-last-of-type(1) {
          @include omega()
        }
      }

      &.speed {
        &:before {
          background-image: url(./../img/icons/icon-1.png);
        }
      }
      &.quality {
        &:before {
          background-image: url(./../img/icons/icon-2.png);
        }
      }
      &.garanty {
        &:before {
          background-image: url(./../img/icons/icon-3.png);
        }
      }
      &.consult {
        &:before {
          background-image: url(./../img/icons/icon-4.png);
        }
      }

      &:before {
        content: '';
        display: block;
        @include size(144px);
        margin: auto auto 30px;
        background-color: $global-color;
        @include rounded();
        @include background-center();
        background-size: initial;
      }
    }
  }
}

// advantages
.advantages {
  min-height: 360px;
  @include background-center;
  @include dark-overlay(.6);
  padding: 115px 0 65px;
  background-image: url(./../img/advantage-bg.jpg);

  &-list {
    @include container();
  }

  .advantage {
    position: relative;
    font: {
      size: $font-size-major;
      weight: $font-weight-bold;
    }
    line-height: $line-height-normal;
    color: $global-color;
    text-align: center;
    margin-bottom: 50px;

    @include sm-lg {
      @include span-columns(6);
      &:nth-of-type(even) {
        @include omega();
      }
    }

    @include lg {
      margin-bottom: 0;
      @include span-columns(3);
      &:nth-last-of-type(1) {
        @include omega();
      }
    }

    &:after {
      content: '';
      @include absolute(65% null null 50%);
      @include size(60px 3px);
      background-color: $brand-color;
      transform: translate(-50%, -50%);
    }

    span {
      display: block;
      font-size: $font-size-medium;
      margin-top: 40px;
    }
  }
}

.main-text {
  @include container();
  margin: 50px auto 30px;

  @include sm {
    margin: 85px auto 55px;
  }
}

//footer
.footer {
  background-color: $gray;

  &-content {
    @include container();
    padding-top: 40px;
    padding-bottom: 20px;

    .col {
      position: relative;
      @include sm {
        @include span-columns(4);
        &:nth-last-of-type(1) {
          @include omega();
        }
      }

      &.first {
        @include sm {
          left: 34.11922%;
        }
      }

      &.second {
        @include sm {
          right: 34.11922%;
        }
      }
    }
  }
}

.footer-logo {

  > img {
    @include img-responsive();
    margin: auto;
  }
}

.social {
  @include size-reset();
  @include list-unstyled();
  text-align: center;
  margin: 0 auto;

  li {
    display: inline-block;

    a {
      display: block;
      color: $global-color;
      transition: $a-transition;
      font-size: $font-size-base - 1;

      @include sm {
        font-size: $font-size-base;
      }

      &:before {
        font-size: $font-size-medium;
        padding: 30px 20px;
      }

      &:hover {
        color: $brand-color;
      }
    }
  }
}

.copyright {
  color: $global-color;
  text-align: center;
  padding-bottom: 10px;

  @include sm {
    text-align: right;
    padding: 150px 0 0;
  }

  @include lg {
    padding: 110px 0 0;
  }

  span {
    display: block;
    padding-top: 10px;
  }
}

.footer-contacts {
  @include size-reset();
  @include list-unstyled();
  padding: 20px 0 40px;

  @include sm {
    padding: 0;
  }

  li {
    display: block;
    color: $global-color;

    &:before {
      padding-right: 20px;
      width: 15px;
      margin-right: 20px;
      padding-right: 0;
    }

    a {
      display: block;
      padding: 7px 0;
      color: $global-color;
      transition: $a-transition;

      &:before {
        width: 15px;
        margin-right: 20px;
        padding-right: 0;
      }

      &:hover {
        color: $brand-color;
        text-decoration: underline;
      }

      &.fa-phone,
      &.fa-globe {
        &:before {
          font-size: $font-size-base;
        }
      }

      &.fa-mobile {
        &:before {
          font-size: $font-size-medium - 2;
        }
      }
    }
  }
}

// about us

.top-banner {
  margin-top: 60px;
  min-height: 144px;
  @include background-center();

  @include sm {
    margin-top: 96px;
  }

  @include md {
    margin-top: 0;
  }

  > img {
    @include img-responsive();
    max-height: 120px;
    margin: auto;
    padding: 25px 0 0;
  }
}

.about-wrapper {
  background-color: $gray-light;
}

.about {
  @include container;

  @include sm {
    padding: 0 65px 100px;
  }

  .first-block {
    margin-bottom: 80px;

    p {
      font: {
        size: $font-size-medium;
        style: $font-style-italic;
      }
      line-height: $line-height-normal * 1.5;

    }
  }

  p {
    margin-bottom: 15px;
    text-align: left;
    font-size: $font-size-base;

    @include sm {
      margin-bottom: 30px;
      font-size: $font-size-small;
    }
  }

  h2, .h2 {
    text-align: center;
    margin-top: 0;
    padding-top: 45px;

    @include sm {
      padding-top: 85px;
    }
  }

  h3, .h3 {
    font-weight: $font-weight-black;
    text-transform: none;
    margin: 30px 0 15px;

    @include sm {
      margin: 60px 0 35px;
    }
  }

  p + ul {
    @include sm {
      margin-top: -20px;
    }
  }

  ul {
    @include size-reset();
    list-style-type: none;
    padding-left: 10px;
    margin-bottom: 20px;

    @include sm {
      padding-left: 30px;
      margin-bottom: 40px;
    }

    li {
      font-size: $font-size-base;

      @include sm {
        font-size: $font-size-small;
        line-height: $line-height-normal * 2;
      }

      &:before {
        content: '-';
        padding-right: 7px;
      }
    }
  }
}

.info {
  position: relative;

  &:after {
    content: '';
    @include absolute(null 0 0 null);
    @include size(62px 146px);
    background: {
      repeat: no-repeat;
      image: url(./../img/info.png);
      size: inherit;
    }
  }
}

.bold {
  font-weight: $font-weight-bold;
}

.italic {
  font-style: $font-style-italic;
}

// contacts
.contacts-wrapper {
  margin-top: 60px;
  background: {
    repeat: no-repeat;
    image: url(./../img/map.jpg);
    size: cover;
  }

  @include sm {
    margin-top: 96px;
  }

  @include lg {
    margin-top: 0;
  }

  .contacts {
    @include container;
    padding-top: 45px;
    padding-bottom: 65px;

    @include sm {
      padding-top: 85px;
      padding-bottom: 115px;
    }

    h2, .h2 {
      text-align: center;
      margin-top: 0;
    }

    &-footer-title {
      font-weight: $font-weight-black;
      text-transform: none;
    }
  }
}

.row {
  @include cl;
}

.contact-page {
  &.footer-contacts {
    @include sm {
      margin-top: 30px;
    }

    li,
    a {
      font-size: $font-size-medium;
      color: $gray-base;

      &:before {
        font-size: $font-size-medium !important;
        padding-right: 24px;
      }
    }

    a.fa-mobile:before {
      font-size: $font-size-large !important;
    }

    @include sm {
      @include span-columns(6);
    }
  }
}

.form {
  margin-bottom: 60px;

  @include sm {
    @include span-columns(6);
  }

  .btn-wrapper {
    .btn {
      display: block;
      width: 100%;
    }
    @include sm {
      text-align: right;

      .btn {
        display: inline-block;
        width: auto;
      }
    }
  }
}

// articles
.articles-wrapper {
  background-color: $gray-light;
}

.articles {
  @include container;
  padding-top: 45px;
  padding-bottom: 55px;

  @include sm {
    padding-top: 85px;
    padding-bottom: 55px;
  }

  h2, .h2 {
    text-align: center;
    margin-top: 0;
  }

}

.article-block {
  display: block;
  margin-bottom: 24px;
  padding: 24px;
  background-color: $global-color;
  border-radius: 20px;
  will-change: box-shadow;
  transition: box-shadow $a-transition;
  box-shadow: 0px 14px 18px 2px rgba(0, 0, 0, 0.15);

  @include max-sm {
    max-height: 380px;
    overflow: hidden;
  }

  @include sm {
    @include cl;
  }

  .article-img {
    height: 170px;
    margin-bottom: 30px;
    @include background-center();
    border-radius: 5px;

    @include sm {
      @include span-columns(3);
      margin-bottom: 0;
    }
  }

  .article-content {

    @include sm {
      @include span-columns(9);
    }

    .article-title {
      margin-bottom: 16px;
      font-weight: $font-weight-bold;
      color: $gray-base;
    }

    .article-text {
      color: $gray-article;
      font-size: $font-size-base;
      line-height: $line-height-normal * 1.5;
    }
  }

  &:hover {
    @include sm {
      box-shadow: 0px 14px 18px 2px $brand-color;
    }
  }

}

.article-single-wrapper {
  background-color: $gray-light;
}

.article-single {
  @include container;
  padding-top: 45px;
  padding-bottom: 55px;

  @include sm {
    padding-top: 85px;
    padding-bottom: 55px;
  }

  h2, .h2 {
    text-align: center;
    margin-top: 0;
  }

}

.article-single-content {
  padding: 50px 24px;
  background-color: $global-color;
  border-radius: 20px;
  box-shadow: 0px 14px 18px 2px rgba(0, 0, 0, 0.15);

  .article-single-content-img {
    height: 350px;
    margin-bottom: 30px;
    @include background-center();
    border-radius: 5px;
  }

  p {
    font-size: $font-size-small;
  }
}

.article-single-content-footer {
  @include max-sm {
    .btn {
      display: block;
      width: 100%;
    }
  }
  @include sm {
    text-align: right;
  }
}

// catalog page

.catalog-wrapper {
  background-color: $gray-light;
}

.catalog {
  @include container;
  padding-top: 30px;
  padding-bottom: 50px;

  @include sm {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  h1, .h1 {
    text-align: center;
    margin-top: 0;

    @include sm {
      text-align: left;
    }
  }

}

.catalog-item {
  @include background-center();
  margin-bottom: 30px;
  @include rounded(5px);
  overflow: hidden;
  @include dark-overlay(.4);

  @include sm {
    @include span-columns(6);
  }

  @include lg {
    @include span-columns(4);
    @include dark-overlay(.6);
    cursor: pointer;

    &:after {
      will-change: opacity;
      transition: opacity $a-transition;
      opacity: 0;
    }
  }

  @include sm-lg {
    min-height: 300px;
    &:nth-of-type(even) {
      @include omega();
    }
  }

  @include lg {
    height: 264px;
    @include span-columns(4);
    &:nth-of-type(3n) {
      @include omega();
    }
  }

  &:hover {
    @include lg {
      &:after {
        opacity: 1;
      }

      .catalog-submenu {
        top: 30px;

        > ul {
          opacity: 1;
        }
      }
    }
  }
}

.category-item {
  position: relative;
  display: block;
  @include background-center();
  margin-bottom: 30px;
  @include rounded(5px);
  overflow: hidden;
  @include dark-overlay(.3);
  min-height: 200px;

  &:after {
    will-change: background-color;
    transition: background-color $a-transition;
  }

  @include sm {
    @include span-columns(6);
  }

  @include lg {
    @include span-columns(4);
    @include dark-overlay(.3);
    cursor: pointer;
  }

  @include sm-lg {
    min-height: 300px;
    &:nth-of-type(even) {
      @include omega();
    }
  }

  @include lg {
    height: 264px;
    @include span-columns(4);
    &:nth-of-type(3n) {
      @include omega();
    }
  }

  &:hover {
    @include lg {
      &:after {
        background: rgba(0, 0, 0, .6);
      }
    }
  }

  .title {
    @include middle-center;
    color: $global-color;
    font: {
      size: $font-size-medium;
      weight: $font-weight-bold;
    }
  }
}

.catalog-submenu {
  padding: 20px;

  @include sm {
    padding: 30px;
  }

  @include lg {
    padding: 0;
  }

  @include lg {
    @include absolute(210px null null 30px);
    will-change: top;
    transition: top $a-transition;
  }

  .title {
    color: $global-color;
    font: {
      size: $font-size-medium;
      weight: $font-weight-bold;
    }
  }

  > ul {
    @include list-unstyled();
    @include size-reset();

    @include lg {
      opacity: 0;
      will-change: opacity;
      transition: opacity $a-transition;
    }

    > li {
      display: block;

      a {
        display: block;
        color: $global-color;
        padding: 6px 0;
        font-size: $font-size-small;

        @include lg {
          will-change: color;
          transition: color $a-transition;

          &:hover {
            color: $brand-color;
          }
        }
      }
    }
  }
}

// product page
.product-wrapper {
  background-color: $gray-light;
}

.product {
  @include container;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: transparent;

  @include sm {
    padding-top: 120px;
  }

  h2, .h2 {
    text-align: center;
    margin-top: 0;
  }
}

.product-content {
  @include cl;

  @include sm {
    padding-top: 50px;
  }

  .img {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 240px;
    @include background-center();

    @include sm {
      height: 400px;
    }

    @include lg {
      @include span-columns(6);
    }

    &:before {
      content: '';
      @include absolute(0 null null 0);
      @include size(100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 80%);

    }

  }
  .info {
    padding-top: 30px;

    @include sm {
      padding-top: 60px;

      p {
        font-size: $font-size-medium;
        line-height: 1.5;
      }
    }

    @include lg {
      @include span-columns(6);
    }

  }
}

.product-text {
  @include sm {
    padding: 50px;
  }

  h2 {
    text-transform: none;
    text-align: left;
    margin-bottom: 20px;
    font: {
      size: $font-size-small;
      weight: $font-weight-bold;
    }

    @include sm {
      margin-bottom: 30px;
      font: {
        size: $font-size-medium;
        weight: $font-weight-black;
      }
    }
  }

  p {
    font-size: $font-size-small;
    text-align: left;
  }
}

.product-gallery {
  background-color: $global-color;

  .gallery-wrapper {
    @include container;
    padding-top: 50px;
    padding-bottom: 50px;

    .h2 {
      margin-top: 0;
      text-transform: none;
    }
  }
}

.gallery {
  @include cl;

  > a {
    display: block;
    @include background-center();
    margin-bottom: 24px;
    border-radius: 5px;
    overflow: hidden;
    height: 200px;

    @include sm {
      height: 120px;
      @include span-columns(4);
    }

    @include sm-lg {
      &:nth-of-type(3n) {
        @include omega;
      }
    }

    @include lg {
      @include span-columns(2);

      &:nth-of-type(6n) {
        @include omega;
      }

      &:hover {
        @include dark-overlay(.4);
      }
    }
  }
}

.page404{
  text-align: center;

  img{
    @include img-responsive();
    margin: 30px auto 0;

    @include sm{
      margin: 50px auto 0;
    }

    @include lg{
      margin: 70px auto 0;
    }
  }
}

.wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors{
  display: none !important; 
}