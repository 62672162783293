html{
  box-sizing: border-box;
}

*{
  box-sizing: inherit;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
}

a,
button,
[role='link'],
[role='button']{
  cursor: pointer;

  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
}

a{
  &,
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

