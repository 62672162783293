
.form-group {
  margin-bottom: 25px;
  
  
  br{
    display: none;
  }
}

label {
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: $font-size-base;
  line-height: $line-height-normal;
  color: $gray-base;
  background-color: #fff;
  background-image: none;
  border: 2px solid #b8becc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  &,
  &:active,
  &:hover,
  &:focus{
    outline: none;
  }

  &:focus{
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px $brand-color;
  }
}

@include placeholders($gray);


textarea,
[name='message']{
  resize: none;
  min-height: 120px;
  padding-top: 12px !important;
}