.pagination-block {
  text-align: center;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 35px 0 0;
  @include cl;
}

.pagination > li {
  display: block;
  float: left;
}

.pagination > li > a {
  position: relative;
  display: block;
  @include size(50px);
  padding: 17px 20px;
  margin-left: - 1px;
  line-height: 1.42857143;
  color: $gray-base;
  text-decoration: none;
  background-color: transparent;
  font: {
    size: $font-size-xs;
    weight: $font-weight-bold;
  }
  @include rounded();
  will-change: color, background-color;
  transition: color $a-transition, background-color $a-transition;
}

.pagination > li {
  &.active,
  &:hover {
    a {
      color: $global-color;
      background-color: $brand-color;
    }
  }
}

