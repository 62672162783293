// Regular
@font-face {
  font-family: 'Lato';
  src: url('./../fonts/Lato-Regular.woff2') format('woff2'),
       url('./../fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Bold
@font-face {
  font-family: 'Lato';
  src:  url('./../fonts/Lato-Bold.woff2') format('woff2'),
        url('./../fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


// Black
@font-face {
  font-family: 'Lato';
  src:  url('./../fonts/Lato-Black.woff2') format('woff2'),
        url('./../fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// Italic
@font-face {
  font-family: 'Lato';
  src:  url('./../fonts/Lato-Italic.woff2') format('woff2'),
        url('./../fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}


// FontAwesome
@font-face {
  font-family: 'FontAwesome';
  src:    url('./../fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'),
          url('./../fonts/fontawesome-webfont.woff?v=4.5.0') format('woff');
  font-weight: normal;
  font-style: normal;
}