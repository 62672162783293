.btn {
  display: inline-block;
  font-weight: $font-weight-bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 30px;
  text-transform: uppercase;
  transition: $a-transition;
  line-height: $line-height-normal;
  font:{
    size: $font-size-xs;
    weight: $font-weight-bold;
  }
  background: $brand-color;
  color: $global-color;
  padding: 16px 33px;

  &,
  &:active,
  &:focus{
    text-decoration: none;
    outline: none;
  }
  &:hover{
    background-color: darken($brand-color, 10%);
  }

  &:active{
    background-color: lighten($brand-color, 5%);
  }
}

