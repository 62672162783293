@mixin container{
  @include outer-container($container-xs);

  @include sm{
    max-width: $container-sm;
  }

  @include md{
    max-width: $container-md;
  }

  @include lg{
    max-width: $container-lg;
  }
}