p {
  font-size: $font-size-base;
  margin: 0 0 20px;
  color: $text-color;
  line-height: $line-height-base;

  @include sm {
    line-height: $line-height-normal * 2;
    text-align: justify;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $gray-base;
  line-height: $line-height-base;
}

h1, .h1 {
  margin-bottom: 40px;
  font: {
    size: $font-size-large;
    weight: $font-weight-bold;
  }
  text-transform: none;

  @include sm {
    margin-bottom: 55px;
    font: {
      size: $font-size-major;
      weight: $font-weight-black;
    }
  }
}

h2, .h2 {
  margin: 50px 0 50px;
  font: {
    size: $font-size-medium;
    weight: $font-weight-black;
  }

  @include sm {
    margin: 85px 0 70px;
    font: {
      size: $font-size-title;
    }
  }
}

h3, .h3 {
  font-size: $font-size-medium;

  @include sm {
    font-size: $font-size-large;
  }
}

.green {
  position: relative;
  text-align: center;

  &:after {
    content: '';
    @include absolute(null null -30px 50%);
    transform: translateX(-50%);
    @include size(120px 6px);
    background-color: $brand-color;
  }
}

