/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

//.swiper-container-android .swiper-slide,
//.swiper-wrapper {
//  -webkit-transform: translate3d(0px, 0, 0);
//  -moz-transform: translate3d(0px, 0, 0);
//  -o-transform: translate(0px, 0px);
//  -ms-transform: translate3d(0px, 0, 0);
//  transform: translate3d(0px, 0, 0);
//}
//
//.swiper-container-multirow > .swiper-wrapper {
//  -webkit-box-lines: multiple;
//  -moz-box-lines: multiple;
//  -ms-flex-wrap: wrap;
//  -webkit-flex-wrap: wrap;
//  flex-wrap: wrap;
//}
//
//.swiper-container-free-mode > .swiper-wrapper {
//  -webkit-transition-timing-function: ease-out;
//  -moz-transition-timing-function: ease-out;
//  -ms-transition-timing-function: ease-out;
//  -o-transition-timing-function: ease-out;
//  transition-timing-function: ease-out;
//  margin: 0 auto;
//}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
//.swiper-container-autoheight,
//.swiper-container-autoheight .swiper-slide {
//  height: auto;
//}

//.swiper-container-autoheight .swiper-wrapper {
//  -webkit-box-align: start;
//  -ms-flex-align: start;
//  -webkit-align-items: flex-start;
//  align-items: flex-start;
//  -webkit-transition-property: -webkit-transform, height;
//  -moz-transition-property: -moz-transform;
//  -o-transition-property: -o-transform;
//  -ms-transition-property: -ms-transform;
//  transition-property: transform, height;
//}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
//.swiper-wp8-horizontal {
//  -ms-touch-action: pan-y;
//  touch-action: pan-y;
//}

//.swiper-wp8-vertical {
//  -ms-touch-action: pan-x;
//  touch-action: pan-x;
//}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  @include middle-vertical;
  @include size(50px);
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  background: $global-color;

  [class*="fa-"]:before{
    @include middle-center;
    padding: 0;
    font-size: $font-size-medium;
    color: $brand-color;
  }
}

.swiper-button-prev{
  left: 30px;
}

.swiper-button-next{
  right: 30px;
}

.swiper-container {
  @include size(100% 540px);
  margin-top: 60px;

  @include sm{
    margin-top: 96px;
  }
  
  @include lg{
    margin-top: 0;
  }
}

.swiper-slide {
  position: relative;
  display: block;
  height: 540px;
  @include background-center();

  .logo{
    @include middle-horizontal;
    margin-top: 60px;
    > img{
      @include img-responsive();
    }
  }

  .title{
    @include middle-horizontal;
    bottom: 0;
    margin-bottom: 150px;
    font:{
      size: $font-size-large;
      weight: $font-weight-black;
    }
    line-height: $line-height-base;
    text:{
      align: center;
      transform: uppercase;
    }
    color: $global-color;

    @include sm{
      font:{
        size: $font-size-lg;
        weight: $font-weight-black;
      }
      line-height: $line-height-normal;
    }
  }
}
