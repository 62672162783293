.btn-close-modal {
  @include absolute(0 0 null null);
  padding: 28px;
  @include size(12px);
  background-image: url(./../img/close.png);
  @include background-center();
  will-change: transform;
  transition: $a-transition;
  cursor: pointer;

  &:hover {
    transform: rotate(.25turn);
  }
}

.modal-open {
  overflow: hidden !important;
}

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.fade .modal-dialog {
    transform: scale(4);
    opacity: 0;
    will-change: transform;
    transition: .35s ease-in-out;
  }
  &.in .modal-dialog {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-dialog {
  position: relative;
  width: 100%;

  @include sm{
    width: 520px;
  }
}

.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  border: 1px solid $modal-content-border-color;
  border-radius: 0;
  background-clip: padding-box;
  outline: 0;
  padding: 30px;

  @include sm{
    padding: 60px;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: rgba(0, 0, 0, 54);
  border-radius: 0;
  will-change: transform;
  opacity: 0;
  transform: scale(0);

  &.fade {
    transition: transform .6s ease-in-out, opacity .35s .15s ease-in-out;
  }
  &.in {
    opacity: $modal-backdrop-opacity;
    transform: scale(1);
    border-radius: 0;
  }
}

.modal-header {
  padding: $modal-title-padding;
  border-bottom: 1px solid $modal-header-border-color;
  @include cl;
}

// Close icon
.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: $modal-title-line-height;
}

.modal-body {
  position: relative;
  @include cl;
}

.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include cl; // clear it in case folks use .pull-* classes on buttons
}

.profile-img {
  @include size(270px);
  @include background-center();
  float: left;
}

.profile-info {
  position: relative;
  width: 405px;
  min-height: 270px;
  margin-left: 30px;
  float: left;

  .name {
    position: relative;
    min-height: 60px;
    font: {
      size: $font-size-large+3;
      weight: $font-weight-bold;
    }
    line-height: $line-height-normal;
    color: $gray-base;

    &:after {
      content: '';
      @include absolute(null 0 0 0);
      @include size(100%);
      //border-bottom: 1px solid $modal-border-color;
    }

    span {
      display: block;
      padding-top: 10px;
      font: {
        size: $font-size-base;
        weight: $font-weight-normal;
      }
      //color: $modal-border-color;

    }
  }

}

.modal-body{
  @include cl;

  .form{
    @include span-columns(12 of 12);
    margin: 0;
  }
}
